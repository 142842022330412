import React, { useRef, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

const FiltreNavigation = ({ data, FilterTags }) => {

    const dispatch = useDispatch();
    const posts = data.edges;
    const [tagsState, setTagsState] = useState(FilterTags);
    const [windowStateWrapper, setWindowStateWrapper] = useState(FilterTags);

    let tags = [];

    posts.forEach((edge) => {
        tags = tags.concat(edge.node.frontmatter.tags);
    })

    let uniq = [...new Set(tags)];

    function setTags(tagname)
    {

        setTagsState(FilterTags);
        tagsState.some(r=> tagname.includes(r)) === false && setTagsState(tagsState => [...tagsState, tagname]);
        tagsState.some(r=> tagname.includes(r)) === true && setTagsState(tagsState => tagsState.filter((e)=>(e !== tagname)));
    }

    useEffect(() => {
        const windowGlobal = typeof window !== 'undefined' && window
        setWindowStateWrapper(windowGlobal);

        tagsState.length > 0 && dispatch({ type: 'setTags', value: tagsState }); 
        tagsState.length === 0 && dispatch({ type: 'setTags', value : []}); 

      }, [tagsState, windowStateWrapper]);

    return (
        <>
            <div className="w-full flex items-center justify-center mt-10 lg:mt-10 xxl:mt-20">
                <div className="flex flex-row gap-5 lg:gap-40 mr-0 lg:mr-40">
                    <div className="flex gap-5 items-center justify-start h-full w-1/3 font-Gilroy_extrabold tracking-widest2 text-black uppercase text-xs lg:text-xxs xxl:text-xs pb-2 ">
                        Filtres
                <div className="w-8 h-2 bg-black"></div>
                    </div>
                    <div className="flex text-base lg:text-sm xxl:text-base flex-no-wrap whitespace-no-wrap flex-col lg:flex-row gap-0 lg:gap-5">
                        <div className="w-full">
                            {uniq.slice(0, 6).map((item, i) =>
                                <div onClick={()=>setTags(item)} style={{ ...FilterTags.includes(item) && { color: "#136F63" } }} key={i} className={"cursor-pointer font-Gilroy_medium tracking-wide transition-colors duration-500 fill-grey hover:text-tactileGreen"}>{item}</div>
                            )
                            }
                        </div>
                        <div className="w-full">
                            {uniq.slice(6).map((item, i) =>
                                <div onClick={()=>setTags(item)} style={{ ...FilterTags.includes(item) && { color: "#136F63" } }} key={i} className="cursor-pointer font-Gilroy_medium tracking-wide transition-colors duration-500 fill-grey hover:text-tactileGreen">{item}</div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(state => ({
    FilterTags: state.FilterTags.tags,
}), null)(FiltreNavigation);