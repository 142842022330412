import React from "react";
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { connect } from 'react-redux';
import Img from "gatsby-image/withIEPolyfill"
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import { isBrowser } from "react-device-detect";
import FooterLayout from "../components/footer/footer";
import { WritingObserver } from "../components/animations/writing";
import WebglWrapper from "../components/webgl/curtain/wrapper";
import Smoothscroll from "../components/Smoothscroll/smoothscroll";
import Linkwrapper from "../components/linkWrapper/linkWrapper";
import Link from "gatsby-link" // not error 
import FiltreNavigation from "../components/UI/Filtres/filtres";

// import { DisplacementWrapper } from "../components/webgl";

function TagRoute({ data, pageContext, scrollHeight, FilterTags, location }) {

  const { scrollYProgress, scrollY } = useViewportScroll();

  const tag = pageContext.tags || "";
  
  return (
    <>
      <SEO title={`projets ${tag}`} />
      <ParallaxForms scrollYProgress={scrollYProgress} data={data} />
      <Smoothscroll
        className="flex lg:pt-32 justify-center flex-col items-center w-full  border-b-2 z-20">
        <Header data={data} />
        <Grid FilterTags={FilterTags} scrollHeight={scrollHeight} scrollY={scrollY} data={data} />
        <Contact />
        <FooterLayout />
      </Smoothscroll>
    </>
  )
}

const PlusProjets = ({ data }) => {
  return (
    <>
      <div className="w-full flex">
        <div className="w-0 lg:w-3/4"></div>
        <div className="flex relative overflow-visible w-full lg:mr-20 lg:w-1/4 items-center justify-center  lg:items-end lg:justify-end text-black tracking-normal">
          <Link to="/projets/">
            <Linkwrapper mouseIcon="discover" className="flex flex-row items-center justify-center gap-2">
              <div className="relative z-40 flex items-center justify-center flex-row gap-4"><span className="text-3xl font-Gilroy_ExtraBoldItalic tracking-normal">Plus d'histoires </span><span className="text-6xl font-Gilroy_extrabold">+</span></div>
            </Linkwrapper>
          </Link>
          <div className="absolute right-0 h-full z-50 top-0 w-20 transform translate-x-5 pointer-events-none">
            <Img
              fluid={data.formBgPlus2.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="forme"
            />
          </div>
          <div className="absolute w-75 z-30 -mr-20 transform translate-x-1/2 -translate-y-1/2 right-0 top-0">
            <Img
              fluid={data.formBgPlus.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="forme"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const Contact = () => {
  return (
    <>
      <section className=" w-full flex items-center justify-center my-20">
        <div className="w-3/4">
        <Link to="/contact/">
          <Linkwrapper mouseIcon="contact">
            <div className="flex flex-col text-center items-center">
            <h1 className="font-Gilroy_ExtraBoldItalic stroke text-4xl lg:text-4xl xl:text-6xl leading-none break-normal">Vous souhaitez partager</h1>
              <div className="w-4/5 lg:w-2/4 -mt-3 lg:-mt-6"><WritingObserver reset={true} text="UneHistoire" /></div>
            </div>
          </Linkwrapper>
        </Link>
        </div>
      </section>
    </>
  )
}

const Header = ({data}) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:block w-full lg:w-3/4 items-center justify-center h-screen mb-0 lg:mb-32 lg:h-full">
        <div className="flex flex-col items-center justify-center">
          <div className="lg:w-3/4 xxl:w-3/4 -mb-5 lg:-mb-10 xxl:-mb-12"><WritingObserver text="histoires" delay="500" /></div>
          <motion.p className="px-10 lg:px-0 relative font-Gilroy_ExtraBoldItalic text-3xl lg:text-5xl xxl:text-7xl text-center text-black leading-none z-20"
            initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 2.5,
              delay: 0.3,
              staggerChildren: 0.35
            }}
          >à partager</motion.p>
          <div className="">
            <motion.p className="relative px-10 lg:px-0 justify-center text-center align-middle font-Gilroy_ExtraBoldItalic mt-5 lg:mt-5 text-xl lg:text-xl xxl:text-2xl text-black"
              initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
              transition={{
                type: 'spring',
                damping: 15,
                stiffness: 60,
                duration: 1.5,
                delay: 0.5,
                staggerChildren: 0.35
              }}
            >
              Saisir l’essence d’un projet<br></br> pour révéler son histoire.
     </motion.p>
          </div>
        </div>
        <FiltreNavigation data={data.allMarkdownRemark} />
      </div>
    </>
  )
}

const ParallaxForms = ({ data, scrollYProgress }) => {

  const yvalue = useTransform(scrollYProgress, [0, 1], [0, -350]);
  const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2, restSpeed: 0.5 })

  const yvalue2 = useTransform(scrollYProgress, [0, 1], [0, -300]);
  const yspring2 = useSpring(yvalue2, { damping: 20, stiffness: 5, mass: 0.2, restSpeed: 0.5 })

  const yvalue3 = useTransform(scrollYProgress, [0, 1], [0, -250]);
  const yspring3 = useSpring(yvalue3, { damping: 20, stiffness: 5, mass: 0.2, restSpeed: 0.5 })

  const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 100]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2, restSpeed: 0.5 })

  return (
    <>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 mt-56 ml-16 left-0 w-20 z-0">
        <Img
          fluid={data.form1.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring2 : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 mt-56 ml-32 left-0 w-10 z-0">
        <Img
          fluid={data.form2.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring2 : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 ml-56 right-0 w-90 z-0">
        <Img
          fluid={data.form3.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 pt-64 mt-64 mt- -mr-10 right-0 w-24 z-0">
        <Img
          fluid={data.form4.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring3 : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 mr-20 mt-40 right-0 w-16 z-0">
        <Img
          fluid={data.form5.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring2 : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 mr-56 mt-40 right-0 w-4 z-0">
        <Img
          fluid={data.form6.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 mr-56 mt-56 right-0 w-6 z-0">
        <Img
          fluid={data.form7.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
    </>
  )
}

const Grid = ({ data, FilterTags }) => {

  const posts = data.allMarkdownRemark.edges;

  return (
    <>
      <div
        className="flex flex-col lg:flex-row flex-wrap w-full lg:w-4/5 pb-20 row-gap-20 lg:row-gap-0"
      >
        {posts.map((post) => (
          (post.node.frontmatter.path !== null && (post.node.frontmatter.tags.some(r=> FilterTags.includes(r)) || FilterTags.length===0) ) &&
          <div key={post.node.frontmatter.path} className="flex project-grid items-start justify-center w-full lg:w-1/3">
            <div
              className="flex flex-col justify-between list-none w-9/12 relative ">
              <WebglWrapper data={data} post={post} iteration={post.node.frontmatter.titre} />
            </div>
          </div>
        ))}
        {/* <PlusProjets data={data} /> */}
      </div>
    </>
  )
}

export default connect(state => ({
  scrollHeight: state.Scrollvalue.scrollHeight,
  FilterTags: state.FilterTags.tags
}), null)(TagRoute);

export const tagPageQuery = graphql`
  query ProjectPage {
    site {
      siteMetadata {
        title
      }
    }
    form1: file(relativePath: { eq: "UI/projets/blob_tags_1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       form2: file(relativePath: { eq: "UI/projets/blob_tags_2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       form3: file(relativePath: { eq: "UI/projets/blob_tags_3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       form4: file(relativePath: { eq: "UI/projets/blob_tags_4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       form5: file(relativePath: { eq: "UI/projets/blob_tags_5.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       form6: file(relativePath: { eq: "UI/projets/blob_tags_6.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       form7: file(relativePath: { eq: "UI/projets/blob_tags_7.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
       }
       formBgPlus: file(relativePath: { eq: "UI/formes/index/forme_grey_bg_plus.png" }) {
        childImageSharp {
          fluid(quality: 98, maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       },
       formBgPlus2: file(relativePath: { eq: "UI/formes/index/bg-plus.png" }) {
        childImageSharp {
          fluid(quality: 98, maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
       },
    allMarkdownRemark
      (
        filter: {
        fileAbsolutePath: {regex: "/(projets)/"  }}
        sort: { fields: [frontmatter___date], order: DESC }
        )
     {
        edges {
            node {
            frontmatter {
                nouveau
                date
                titre
                path
                description
                tags
                cover { 
                    publicURL
                    childImageSharp {
                    fluid(quality: 95, maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                   }
                  }
                  video 
                  {
                    publicURL
                  }
            }
          }
        }
      }
  }
`
