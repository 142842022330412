import React, { useRef, useState, useEffect } from 'react'
import { motion, AnimatePresence, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import { AppearProject } from '../../animations/projectanim';
import { IntersectionObserver } from "../../layout/intersection";
import ReactPlayer from 'react-player/lazy'
import Linkwrapper from "../../linkWrapper/linkWrapper"
import Link from "gatsby-link" // not error 
import Img from "gatsby-image/withIEPolyfill"
import { isBrowser } from "react-device-detect";
import "./style.css"
import { Canvas, useFrame, render } from 'react-three-fiber'
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

// function Box(props) {
//     // This reference will give us direct access to the mesh
//     const mesh = useRef()

//     // Set up state for the hovered and active state
//     const [hovered, setHover] = useState(false)
//     const [active, setActive] = useState(false)

//     // Rotate mesh every frame, this is outside of React without overhead
//     useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))

//     return (
//         <mesh
//             {...props}
//             ref={mesh}
//             scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
//             onClick={(e) => setActive(!active)}
//             onPointerOver={(e) => setHover(true)}
//             onPointerOut={(e) => setHover(false)}>
//             <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
//             <meshStandardMaterial attach="material" color={hovered ? 'hotpink' : 'orange'} />
//         </mesh>
//     )
// }

const WebglWrapper = ({ post }) => {

    // Rotate mesh every frame, this is outside of React without overhead
    // useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))
    const dispatch = useDispatch();
    const { scrollYProgress } = useViewportScroll();

    const descriptionAppear = {
        hidden: {
            opacity: 0,
            y: 70,
            transition: {
                type: 'tween',
                duration: 0.5,
            }
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 100,
                duration: 0.35,
                delay: 0.3,
                staggerChildren: 0.35
            }
        },
        exit: {
            opacity: 0,
            y: 0,
            transition: {
                type: 'tween',
                duration: 0.5,
            }
        },
    }

    const slideAppear = {
        hidden: {
            opacity: 1,
            scaleY: 1,
            originX: 0,
            originY: 1,
            transition: {
                type: 'tween',
                duration: 0.5,
            }
        },
        show: {
            opacity: 1,
            scaleY: 0,
            originX: 0,
            originY: 1,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 60,
                mass: 0.2,
                delay: 0.1,
                duration: 0.32,
                staggerChildren: 0.35
            }
        },
        exit: {
            opacity: 0,
            transition: {
                type: 'tween',
                duration: 0.5,
            }
        },
    }

    const animationAppear = {
        hidden: {
            opacity: 0, y: 120,
            transition: {
                type: 'tween',
                duration: 0.5,
            }
        },
        show: {
            opacity: 1, y: 0,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 60,
                duration: 0.5,
                staggerChildren: 0.35
            }
        },
        exit: {
            opacity: 0,
            transition: {
                type: 'tween',
                duration: 0.5,
            }
        },
    }

    const VideoTransition = {
        initial: {
            opacity: 0,
            transition: {
                delay: 0.2,
                duration: 0.35,
                type: 'tween'
            },
        },
        enter: {
            opacity: 1,
            transition: {
                delay: 0.2,
                duration: 0.35,
                type: 'tween'
            },
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0.2,
                duration: 0.35,
                type: 'tween'
            },
        },
    };

    const [hoverRef, isHovered] = useState(false);

    // Hook
    // function useHover() {
    //     const [value, setValue] = useState(false);

    //     const ref = useRef(null);

    //     const handleMouseOver = () => setValue(true);
    //     const handleMouseOut = () => setValue(false);

    //     useEffect(
    //         () => {
    //             const node = ref.current;
    //             if (node) {
    //                 node.addEventListener('mouseover', handleMouseOver);
    //                 node.addEventListener('mouseout', handleMouseOut);

    //                 return () => {
    //                     node.removeEventListener('mouseover', handleMouseOver);
    //                     node.removeEventListener('mouseout', handleMouseOut);
    //                 };
    //             }
    //         },
    //         [ref.current] // Recall only if ref changes
    //     );

    //     return [ref, value];
    // }

    const [windowStateWrapper, setWindowStateWrapper] = useState();

    useEffect(() => {

        const windowGlobal = typeof window !== 'undefined' && window

        setWindowStateWrapper(windowGlobal);
    }, [windowStateWrapper]);

    function setTagsWrapper(tagname) {
        windowStateWrapper.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        windowStateWrapper.setTimeout(() => dispatch({ type: 'setTags', value: [tagname] }), 1000);
    }

    //console.log('WebglWrapper.post.node.frontmatter', post.node.frontmatter)

    return (
        <>
            <IntersectionObserver reset={true} thresholdParam={0.3}>
                <AppearProject>
                    <motion.div initial={{ y: 120, opacity: 0 }} variants={animationAppear} className=" overflow-hidden" >
                        <Link to={post.node.frontmatter.path}>
                            <Linkwrapper mouseIcon={post.node.frontmatter.nouveau === true ? "new" : "project"} className="overflow-hidden">
                                <motion.div onMouseEnter={() => isHovered(true)} onMouseLeave={() => isHovered(false)} transition={{ type: 'tween', duration: 0.55 }} className="relative w-full z-30 video-overlay overflow-hidden">
                                    <motion.div
                                        className="overflow-hidden absolute z-20 h-full w-full"
                                    >
                                        <AnimatePresence>
                                            {(isBrowser && hoverRef === true) &&
                                                <motion.div
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    key={post.node.frontmatter.path}
                                                    transition={{
                                                        delay: 0.2,
                                                        duration: 0.35,
                                                        type: 'tween'
                                                    }}
                                                >
                                                    <ReactPlayer
                                                        width='100%'
                                                        height='100%'
                                                        loop={true}
                                                        playing={true}
                                                        controls={false}
                                                        muted={true}
                                                        url={post.node.frontmatter.video?.publicURL} >
                                                    </ReactPlayer>
                                                </motion.div>
                                            }
                                        </AnimatePresence>
                                    </motion.div>
                                    {post.node?.frontmatter?.cover?.childImageSharp &&
                                        <Img
                                            fluid={post.node.frontmatter.cover.childImageSharp.fluid}
                                            objectFit="cover"
                                            width="100%"
                                            height="100%"
                                            objectPosition="50% 50%"
                                            alt={"image"}
                                            className="absolute z-10 top-0"
                                        />
                                    }
                                </motion.div>
                            </Linkwrapper>
                        </Link>
                    </motion.div>
                    <motion.div variants={descriptionAppear}>
                        <motion.h2 className="text-black flex break-words relative font-Gilroy_ExtraBoldItalic tracking-normal text-2xl lg:text-xl xl:text-2xl mb-4 mt-10 px-5 w-full ">
                            {post.node.frontmatter.description}
                        </motion.h2>
                        <div className="flex flex-row flex-wrap items-end gap-0 px-5">
                            {post.node.frontmatter.tags.map((item, i) =>
                                <div onClick={() => setTagsWrapper(item)} className="cursor-pointer font-Gilroy_semibold text-tactileGray tracking-wide text-sm lg:text-xs xxl:text-sm  mx-1 hover:text-tactileGreen transition-colors duration-500" key={i}>
                                        #{item}
                                </div>
                            )}
                        </div>
                    </motion.div>
                </AppearProject>
            </IntersectionObserver>
        </>
    )
}

export default connect(state => ({
    FilterTags: state.FilterTags.tags,
}), null)(WebglWrapper);
